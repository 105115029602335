import axios from '@/axios.js'
import qs from 'qs'

export default {
  fetchNewService({ commit }) {
    return new Promise((resolve, reject) => {
      // axios.get('/packg/getAll')
      //   .then((response) => {
      //     commit('SET_NEW_SERVICES', response.data)
      //     resolve(response)
      //   })
      //   .catch((error) => { reject(error) })

      axios.get(process.env.VUE_APP_GATEWAY_URL+`/api/resource/Healthcare Practitioner?fields=["*"]&filters=[["custom_base_url","=","${window.location.host === 'localhost:8080' ? 'demo.healminder.com' : window.location.host }"]]`, {
        headers: {
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }).then(response => {
        commit('SET_NEW_SERVICES', response.data.data)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  fetchAllCarePlans({ commit }) {
    return new Promise((resolve, reject) => {
      // axios.get('/packg/getAll')
      //   .then((response) => {
      //     commit('SET_ALL_CARE_PLANS', response.data)
      //     resolve(response)
      //   })
      //   .catch((error) => { reject(error) })
      axios.get(process.env.VUE_APP_GATEWAY_URL+'/api/resource/Healthcare Practitioner?fields=["*"]', {
        headers: {
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }).then(response => {
        commit('SET_ALL_CARE_PLANS', response.data.data)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  },
  fetchAllRegistrationCalls({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/registration/getAllRegistrationCalls')
        .then((response) => {
          commit('SET_ALL_REGISTRATION_CALL', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
/*  fetchCarePlan ({ commit }, carePlanId) {
    return new Promise((resolve, reject) => {
      axios.get('/packg/getByUUID?id=' + carePlanId)
        .then((response) => {
          console.log(response)
          commit('SET_CAREPLAN', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },*/
  fetchCarePlanById ({ commit }, payload) {
    return new Promise((resolve, reject) => {

      axios.get('/packg/getByUUID',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          commit('SET_ONE_CAREPLAN', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchCarePlansByOrgId({ commit }, payload) {
    return new Promise((resolve, reject) => {

      axios.get('/packg/filterPackg',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log("START fetchCarePlansByOrgId")

          console.log(response)

          console.log("END fetchCarePlansByOrgId")

          commit('SET_ALL_CARE_PLANS_ORGID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },


  fetchResourcePersonnelById ({ commit }, payload) {
    return new Promise((resolve, reject) => {

      axios.get('/resourcePersonnel/getByUUID',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)

          // urlSlot.url = "/apps/preventiveHealth/book/" + selectedCarePlanId +  "/"  + selectedDayNo  +  "/" + urlSlot.slotId + "/" + slotStartHour + "/"+ slotStartMinute + "";
          commit('SET_ONE_RESOURCE_PERSONNEL', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchResourcePattern ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/appointment/getPackgResourcePatternByUUID',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log("BEFORE SET_RESOURCE_PATTERN response");
          console.log(response.data);
          console.log("BEFORE SET_RESOURCE_PATTERN response");
          commit('SET_RESOURCE_PATTERN', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchServiceById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/serviceRequest/getById',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          commit('SET_ONE_SERVICE_REQUEST', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchActivityById ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/activity/getById',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          commit('SET_ONE_ACTIVITY', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchCarePlanAvailability ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const dayNo = payload.dayNo;
      let newPayload = JSON.parse(JSON.stringify(payload));
      delete newPayload['dayNo'];

      axios.get('/appointment/getPackgAvailabilityCalendar',
        {
          params: newPayload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          var newObj = JSON.parse(JSON.stringify(response.data));
          newObj['dayNo'] = dayNo;
          newObj['productId'] = newPayload.resourceId;
          commit('SET_AVAILABLE_TIMESLOTS', newObj)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
