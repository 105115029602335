// axios
import axios from 'axios'
import {getToken, removeRefreshToken, removeToken} from "@/utils/storage";
import store from './store/store'

// const baseURL = 'http://localhost:8090/'
const baseURL = process.env.VUE_APP_GATEWAY_URL

//const baseURL = ""

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

const service = axios.create({
  baseURL,
  headers: {
    'Authorization': 'Bearer '+getToken(),
    'Content-Type': 'application/json',
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
  }
  // You can add your headers here
})

service.interceptors.request.use(config => {
  // if (getToken()) {
  //   config.headers['Authorization'] ='Bearer ' + getToken()
  // }
  config.headers['Accept'] ='application/json'
  config.headers['Content-Type'] ='application/json;charset=UTF-8'
  config.headers["Access-Control-Allow-Origin"] ='*'
  config.headers["Cache-Control"] ='no-cache'

  return config
}, error => {
    Promise.reject(error)
})

service.interceptors.response.use(response => {
  return response
}, error => {
  const originalRequest = error.config
  console.log("error response ", error.response)
  if (error.response) {
    if (error.response.config.url === '/refresh' && error.response.status === 401) {
      removeToken()
      removeRefreshToken()
      window.location.href = '/pages/loginEmailLink'
    }
    if(error.response.data.message === "Unauthorizated" && !getToken()) {

      window.location = '/pages/loginEmailLink';
    }
    if(error.response.data.message === "Unauthorizated" && getToken()) {
      if(!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true

        store.dispatch('auth/fetchNewAccessToken').then(res => {
          if(res.status == 200) {
            isAlreadyFetchingAccessToken = false
            onAccessTokenFetched(res.data.access_token)
            // setToken(res.data.access_token)
            // return new Promise(resolve => {
            //     error.config.headers['Authorization'] = 'Bearer ' + res.data.access_token;
            //     resolve(service(originalRequest))
            // })
            // return axios(originalRequest)
          } else{
            removeToken()
            window.location = '/pages/loginEmailLink';
          }

        }).catch(() => {
          removeToken()
          window.location = '/pages/loginEmailLink';
        })
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber(access_token => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`
          resolve(axios(originalRequest))
        })
      })

      return retryOriginalRequest

    }
  }
  return Promise.reject(error)
})

export default service
