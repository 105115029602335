import request from './request'


export function getOrganizationCredentials(host) {
  return request({
    url: '/v1/organizations/host-organization',
    headers: {
      'hosturl': host,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    method: 'get',
  })
}
