/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
const specialitys = [
  'Gynecology', 
  'Multi', 
  'Vaccinnation', 
  'Obstetrics', 
  'ENT', 
  'Ophthalmology',
  'Oncology',
  'Neonatology',
  'Pediatrics',
  'Neuro Sciences'
]

export default {
  SET_AVG_REVENUE_BY_PHYSICAN (state, data) {
    state.avgRevenueByPhysican = {}
    state.avgRevenueByPhysican.series = data.series
    state.avgRevenueByPhysican.chartOptions = {
      chart: {
        height: 350,
        type: 'line',
        stacked: true
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      title: {
        // text: 'Average Revenue by Physician'
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1]
      },
  
      labels: specialitys,
  
      yaxis: [
        {
          // max: 10
        },
        {
          opposite: true
        }
      ]
    }
  },
  
  SET_GROSS_REVENUE_2_YEARS (state, data) {
    state.grossRevenue2years = {}
    state.grossRevenue2years.series = data.series
    state.grossRevenue2years.chartOptions = {
        
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [1, 1, 4]
      },
      title: {
        // text: 'XYZ - Stock Analysis (2009 - 2016)',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
  },

  SET_PATIENT_ENCOUTER_BREAKDOWN (state, data) {
    state.patientEncouterBreakdown = {}
    state.patientEncouterBreakdown.series = data.series
    state.patientEncouterBreakdown.chartOptions = {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false
          // columnWidth: '55%',
          // endingShape: 'rounded'
        }
      },
      title: {
        // text: 'Patient Encounter Breakdown',
        align: 'left',
        offsetX: 110
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
      },
      yaxis: {
        title: {
          // text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      }
      // tooltip: {
      //   y: {
      //     formatter (val) {
      //       return `$ ${  val  } thousands`
      //     }
      //   }
      // }
    }
  },

  SET_PATIENT_ENCOUTER_BREAKDOWN_BY_DAYS (state, data) {
    state.patientEncouterBreakdownByDays = {}
    state.patientEncouterBreakdownByDays.series = data.series
    state.patientEncouterBreakdownByDays.chartOptions = {
      chart: {
        type: 'bar',
        height: 350
      },
      title: {
        // text: 'Patient Encounter Breakdown by days',
        align: 'left',
        offsetX: 110
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct']
      },
      yaxis: {
        title: {
          // text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      }
      // tooltip: {
      //   y: {
      //     formatter (val) {
      //       return `$ ${  val  } thousands`
      //     }
      //   }
      // }
    }
  },

  SET_REVENUE_BY_CLINIC (state, data) {

    state.revenueByClinic = {}
    state.revenueByClinic.series = data.data.pieChart.series
    console.log("Start data.series")
    console.log(state.revenueByClinic.series)
    console.log("END data.series")
    state.revenueByClinic.chartOptions = {
      // labels: ['Clinic 1', 'Clinic 2', 'Clinic 3', 'Clinic 4', 'Clinic 5'],
      // colors: themeColors,
      labels: data.config.params.hospitalCodes,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ],
      title: {
        // text: 'Revenue by Clinic',
        align: 'left',
        offsetX: 110
      }
    }
    console.log("START labels")
    console.log(state.revenueByClinic.chartOptions)
    console.log("END labels")

  },

  SET_REVENUE_OP_VS_CHC (state, data) {
    state.revenueOPvsCHC = {}
    state.revenueOPvsCHC.series = data.series
    state.revenueOPvsCHC.chartOptions = {
      chart: {
        height: 350,
        type: 'line'
        
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        // text: 'Revenue OP vs CHC'
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1]
      },
      // labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
      xaxis: {
        // type: 'datetime'
      },
      yaxis: [
        {
          title: {
            // text: 'Website Blog'
          }
      
        }, {
          opposite: true,
          title: {
            // text: 'Social Media'
          }
        }
      ]
    }
  },

  SET_FINANCIAL_OVERVIEW (state, data) {
    state.financialOverview = data
  },

  SET_PATIENT_PAYER_BREAKDOWN_BY_SPECILIATY (state, data) {
    state.patientPayerBreakdownBySpeciliaty = {}
    state.patientPayerBreakdownBySpeciliaty.series = data.series
    state.patientPayerBreakdownBySpeciliaty.chartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      xaxis: {
        
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    }
  }
}
