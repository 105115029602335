export default {
 patientType: 'نوع المريض',
  existing: 'موجود',
  new: 'جديد',
  gender: 'جنس',
  female: 'أنثى',
  male: 'ذكر',
  firstName: 'الاسم الأول',
  lastName: 'اسم العائلة',
  birthDate: 'تاريخ الميلاد',
  mobileNumber: 'رقم الهاتف المحمول',
  email: 'بريد إلكتروني',
  address: 'عنوان',
  postalCode: 'رمز بريدي',
  city: 'City',
  submit: 'يُقدِّم',
  nationality: 'جنسية',
  job: 'وظيفة',
  username: 'اسم المستخدم',
  password: 'كلمة المرور',
  login: 'تسجيل الدخول',
  fullName: 'الاسم الكامل',
  doctorName: 'اسم الطبيب',
  appointmentDate: 'تاريخ الموعد',
  notes: 'ملحوظات',
  confirm: 'يتأكد',
  goToHomePage: 'اذهب إلى الصفحة الرئيسية',
  navbar: {
   healthPlan: 'الخطة الصحية والفحص',
    documentCenter: 'مركز الوثائق',
    checklist: 'تراكم التسجيل'
  },
  landingPage: {
    bookNow: 'احجز الآن',
    whatsNew: 'ما هو الجديد',
    recommendedClasses: 'الفصول الموصى بها',
    mayJoinClasses: 'قد ترغب أيضًا في الانضمام إلى هذه'
  },
  healthPackage: {
  selectDate: 'حدد التاريخ والوقت',
    fillInformation: 'فسحة زمنية',
    confirmation: 'تأكيد',
    confirmDate: 'تأكيد التاريخ',
    confirmTime: 'تأكيد الوقت',
    morning: 'صباح',
    afternoon: 'صباح',
    evening: 'مساء',
    slots: 'فتحات',
  },
  book: {
   packageDescription: 'وصف الخطة الصحية',
    selectDate: 'حدد التاريخ والوقت',
    fillInformation: 'فسحة زمنية',
    confirmation: 'تأكيد',
    successfullBooking: '! تم حجز الموعد بنجاح، شكراً لحجزك',
    beforeBooking: 'يرجى التحقق من تفاصيل موعدك قبل مطابقته!',
    checkDetailsBeforeBooking: 'يرجى التحقق من التفاصيل أدناه لتحديد موعدك القادم:',
    appointmentNotes:'.سيتم إرسال تفاصيل الموعد إلى عنوانك، يرجى التحقق من بريدك الإلكتروني للتذكير',
  }
}
