/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {

 /* SET_TAGS (state, tags) {
    state.taskTags = tags
  },*/
  ADD_RESOURCE_PERSONNEL (state, resource) {
    //state.tasks.unshift(task)
    state.resourcePersonnel = resource
  },
  SET_PERSONNEL_RESOURCES (state, resources) {
    state.resourcesPersonnel = resources.resourcePersonnels;
  },
  SET_EQUIPMENT_RESOURCES (state, resources) {
    state.resourcesEquipment = resources.resourceEquipments;
  },
  SET_ONE_RESOURCE (state, resource) {
    state.resource = resource
  },

/*
  UPDATE_TASK (state, task) {
    const taskIndex = state.resources.findIndex((t) => t.id === task.id)
    Object.assign(state.resources[taskIndex], task)
  },
*/
  REMOVE_RECORD (state, itemId) {
    const userIndex = state.users.findIndex((u) => u.id === itemId)
    state.users.splice(userIndex, 1)
  },
  ADD_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS (state, data) {
    state.personnelResourceAvailableTimeslots.push(data);
  },
  SET_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS (state, data) {
    state.personnelResourceAvailableTimeslots = data;
  },
  ADD_EQUIPMENT_RESOURCE_AVAILABILITY_INTERVALS (state, data) {
    state.equipmentResourceAvailableTimeslots.push(data);
  },
  SET_EQUIPMENT_RESOURCE_AVAILABILITY_INTERVALS (state, data) {
    state.equipmentResourceAvailableTimeslots = data;
  },
  SET_NEW_PERSONAL_RESOURCE_ID (state, data) {
    state.newResourceId = data;
  },
  SET_NEW_EQUIPMENT_RESOURCE_ID (state, data) {
    state.newResourceId = data;
  },
}
