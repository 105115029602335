import en from './en'
import fr from './fr'
import vt from './vt'
import ru from './ru'
import spanish from './spanish'
import cat from './cat'
import uae from './uae'

export default {
  en,
  fr,
  vt,
  ru,
  spanish,
  cat,
  uae,
}
