/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  resourcesPersonnel: [],
  resourcesEquipment: [],
  resourcePersonnel: null,
  resourceEquipment: null,
  resourceSpace: null,
  resourceConsumable: null,
  todoSearchQuery: '',
  todoFilter: null,
  personnelResourceAvailableTimeslots: [],
  newResourceId: 1
}
