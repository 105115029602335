/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  avgRevenueByPhysican: null,
  grossRevenue2years: null,
  patientEncouterBreakdown: null,
  patientEncouterBreakdownByDays: null,
  revenueByClinic: null,
  revenueOPvsCHC: null,
  financialOverview: null,
  patientPayerBreakdownBySpeciliaty: null
}
