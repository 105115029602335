/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js';
import qs from 'qs';
import moment from 'moment-timezone';

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchPersonnelResources ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/resourcePersonnel/getAll')
        .then((response) => {
          commit('SET_PERSONNEL_RESOURCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchEquipmentResources ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/resourceEquipment/getAll')
        .then((response) => {
          commit('SET_EQUIPMENT_RESOURCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAllResourceEquipment ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/resourceEquipment/getAll')
        .then((response) => {
          console.log(response)
          commit('SET_RESOURCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAllResourceSpace ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/resourceSpace/getAll')
        .then((response) => {
          console.log(response)
          commit('SET_RESOURCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAllResourceConsumable ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/resourceConsumable/getAll')
        .then((response) => {
          console.log(response)
          commit('SET_RESOURCES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchOneResource ({ commit}, resourceId) {
    return new Promise((resolve, reject) => {
      resourceId = 'f808a186-ff0c-4f84-bf57-fa07e19ff7e6';
      axios.get('/resourcePersonnel/getByUUID?id=' +  resourceId)
      .then((response) => {
        console.log(response)
        commit('SET_ONE_RESOURCE', response.data)
        resolve(response)
      })
      .catch((error) => { reject(error) })
    })
  },

  addTask ({ commit }, task) {
    return new Promise((resolve, reject) => {


      var config = {
        headers: { "Content-Type": "application/json" }
      };

      //axios.post(HMinderEndPoint + postURL, dataBook, config)

      var idsave;
      axios.post('/resourcePersonnel/save', task, config)
        .then((response) => {

          console.log(response)
          console.log(Object.assign(task, {id: response.data.id}))
          idsave = 60; //response.data.id
          commit('ADD_RESOURCE_PERSONNEL', task)
          resolve(response)

          axios.post('/resourcePersonnel/generateWeeklyScheduleAvailabilityIntervals?endFullTime=31&endFullTime=12&endFullTime=2021&endFullTime=23&endFullTime=59&endFullTime=59&endFullTime=1&endFullTime=0&id='+ idsave+'&startFullTime=1&startFullTime=5&startFullTime=2021&startFullTime=0&startFullTime=0&startFullTime=0&startFullTime=1&startFullTime=0&timeZoneHour=1&timeZoneMinute=0&workingTimePerDayEndHour=17&workingTimePerDayEndHour=17&workingTimePerDayEndHour=17&workingTimePerDayEndHour=17&workingTimePerDayEndHour=17&workingTimePerDayEndHour=17&workingTimePerDayEndHour=17&workingTimePerDayEndMinute=0&workingTimePerDayEndMinute=0&workingTimePerDayEndMinute=0&workingTimePerDayEndMinute=0&workingTimePerDayEndMinute=0&workingTimePerDayEndMinute=0&workingTimePerDayEndMinute=0&workingTimePerDayEndSecond=0&workingTimePerDayEndSecond=0&workingTimePerDayEndSecond=0&workingTimePerDayEndSecond=0&workingTimePerDayEndSecond=0&workingTimePerDayEndSecond=0&workingTimePerDayEndSecond=0&workingTimePerDayStartHour=0&workingTimePerDayStartHour=0&workingTimePerDayStartHour=0&workingTimePerDayStartHour=0&workingTimePerDayStartHour=0&workingTimePerDayStartHour=0&workingTimePerDayStartHour=0&workingTimePerDayStartMinute=0&workingTimePerDayStartMinute=0&workingTimePerDayStartMinute=0&workingTimePerDayStartMinute=0&workingTimePerDayStartMinute=0&workingTimePerDayStartMinute=0&workingTimePerDayStartMinute=0&workingTimePerDayStartSecond=0&workingTimePerDayStartSecond=0&workingTimePerDayStartSecond=0&workingTimePerDayStartSecond=0&workingTimePerDayStartSecond=0&workingTimePerDayStartSecond=0&workingTimePerDayStartSecond=0',{}, config)
          .then(response => {

            console.log(response);
                      return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})

             });

        })
        .catch((error) => { reject(error) })
    })
  },

  createResource({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let url = '';
      if (payload.type === 'personnel') {
        url = 'resourcePersonnel/save';
      }
      if (payload.type === 'equipment') {
        url = 'resourceEquipment/save';
      }
      axios.post(url, payload.payload)
        .then(response => {
          // commit('SET_NEW_PERSONAL_RESOURCE_ID', response.data);
          if (payload.type === 'personnel') {
            dispatch('fetchPersonnelResources');
          }
          if (payload.type === 'equipment') {
            dispatch('fetchEquipmentResources');
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  generatePersonnelResourceTimeslots({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      console.log(state.newResourceId)
      axios.post(`/resourcePersonnel/generateWeeklyScheduleAvailabilityIntervals?id=${state.newResourceId}&startFullTime=${payload.startFullTime}&endFullTime=${payload.endFullTime}`, payload.payload)
        .then(response => {
          if (response.data.availabilityIntervals.length > 0) {
            let data = [];
            response.data.availabilityIntervals.forEach(function (slotData) {
              if (slotData.availability !== 'yes') {
                return;
              }
              const startDate = moment().set({
                'date': slotData.startFullTime[0],
                'month': slotData.startFullTime[1]-1,
                'year': slotData.startFullTime[2],
                'hour': slotData.startFullTime[3],
                'minute': slotData.startFullTime[4]
              });
              const endDate = moment().set({
                'date': slotData.endFullTime[0],
                'month': slotData.endFullTime[1]-1,
                'year': slotData.endFullTime[2],
                'hour': slotData.endFullTime[3],
                'minute': slotData.endFullTime[4]
              }).add(1, "minute");
              data.push({
                'startDate': startDate,
                'endDate': endDate,
                'title': `${startDate.format('MMMM Do YYYY')}, from ${startDate.format('HH:mm')} to ${endDate.format('HH:mm')}`
              });
              // commit('ADD_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS', {
              //   'startDate': startDate,
              //   'endDate': endDate,
              //   'title': `${startDate.format('MMMM Do YYYY')}, from ${startDate.format('HH:mm')} to ${endDate.format('HH:mm')}`
              // });
            });
            commit('SET_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS', data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  generateEquipmentResourceTimeslots({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      console.log(state.newResourceId)
      axios.post(`/resourceEquipment/generateWeeklyScheduleAvailabilityIntervals?id=${state.newResourceId}&startFullTime=${payload.startFullTime}&endFullTime=${payload.endFullTime}`, payload.payload)
        .then(response => {
          if (response.data.availabilityIntervals.length > 0) {
            let data = [];
            response.data.availabilityIntervals.forEach(function (slotData) {
              if (slotData.availability !== 'yes') {
                return;
              }
              const startDate = moment().set({
                'date': slotData.startFullTime[0],
                'month': slotData.startFullTime[1]-1,
                'year': slotData.startFullTime[2],
                'hour': slotData.startFullTime[3],
                'minute': slotData.startFullTime[4]
              });
              const endDate = moment().set({
                'date': slotData.endFullTime[0],
                'month': slotData.endFullTime[1]-1,
                'year': slotData.endFullTime[2],
                'hour': slotData.endFullTime[3],
                'minute': slotData.endFullTime[4]
              }).add(1, "minute");
              data.push({
                'startDate': startDate,
                'endDate': endDate,
                'title': `${startDate.format('MMMM Do YYYY')}, from ${startDate.format('HH:mm')} to ${endDate.format('HH:mm')}`
              });
              // commit('ADD_EQUIPMENT_RESOURCE_AVAILABILITY_INTERVALS', {
              //   'startDate': startDate,
              //   'endDate': endDate,
              //   'title': `${startDate.format('MMMM Do YYYY')}, from ${startDate.format('HH:mm')} to ${endDate.format('HH:mm')}`
              // });
            });
            commit('SET_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS', data);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error)
        });
    })
  },

  getPersonnelResourceTimeslots({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/resourcePersonnel/getById?id=${payload.id}`)
        .then((response) => {
          let data = [];
          response.data.availabilityIntervals.forEach(function (slotData) {
            if (slotData.availability !== 'yes') {
              return;
            }
            const startDate = moment().set({'date': slotData.startFullTime[0], 'month': slotData.startFullTime[1], 'year': slotData.startFullTime[2], 'hour': slotData.startFullTime[3], 'minute': slotData.startFullTime[4]});
            const endDate = moment().set({'date': slotData.endFullTime[0], 'month': slotData.endFullTime[1], 'year': slotData.endFullTime[2], 'hour': slotData.endFullTime[3], 'minute': slotData.endFullTime[4]}).add(1,"minute");
            data.push({
              'startDate': startDate,
              'endDate': endDate,
              'title': `${startDate.format('MMMM Do YYYY')}, from ${startDate.format('HH:mm')} to ${endDate.format('HH:mm')}`
            });
          });
          commit('SET_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS', data)
          resolve(response);
        })
        .catch((error) => { reject(error) })
    })
  },

  getEquipmentResourceTimeslots({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/resourceEquipment/getById?id=${payload.id}`)
        .then((response) => {
          let data = [];
          response.data.availabilityIntervals.forEach(function (slotData) {
            if (slotData.availability !== 'yes') {
              return;
            }
            const startDate = moment().set({'date': slotData.startFullTime[0], 'month': slotData.startFullTime[1], 'year': slotData.startFullTime[2], 'hour': slotData.startFullTime[3], 'minute': slotData.startFullTime[4]});
            const endDate = moment().set({'date': slotData.endFullTime[0], 'month': slotData.endFullTime[1], 'year': slotData.endFullTime[2], 'hour': slotData.endFullTime[3], 'minute': slotData.endFullTime[4]}).add(1,"minute");
            data.push({
              'startDate': startDate,
              'endDate': endDate,
              'title': `${startDate.format('MMMM Do YYYY')}, from ${startDate.format('HH:mm')} to ${endDate.format('HH:mm')}`
            });
          });
          commit('SET_EQUIPMENT_RESOURCE_AVAILABILITY_INTERVALS', data)
          resolve(response);
        })
        .catch((error) => { reject(error) })
    })
  },
/*
  saveResourceEquipment({ commit }, payload) {
    return new Promise((resolve, reject) => {

      axios.post('/packg/filterPackg',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          commit('SET_ALL_CARE_PLANS_ORGID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  saveResourceSpace({ commit }, payload) {
    return new Promise((resolve, reject) => {

      axios.post('/packg/filterPackg',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          commit('SET_ALL_CARE_PLANS_ORGID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  saveResourceConsumable({ commit }, payload) {
    return new Promise((resolve, reject) => {

      axios.post('/packg/filterPackg',
        {
          params: payload,
          paramsSerializer: params => {
            console.log(qs.stringify(params, { arrayFormat: 'repeat' }))
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          commit('SET_ALL_CARE_PLANS_ORGID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  */

  fetchResourcePersonnelAvailability ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log("START fetchResourcePersonannelAvailability ");console.log(payload);console.log("END fetchResourcePersonannelAvailability ");
      var dayNo = payload.dayNo;
      var newpayload = JSON.parse(JSON.stringify(payload));
      delete newpayload['dayNo'];
      console.log("START fetchResourcePersonannelAvailability dayNo");console.log(dayNo);console.log(newpayload);console.log("END fetchResourcePersonannelAvailability dayNo ");

      axios.get('/appointment/getResourcePersonnelAvailabilityCalendar',
        {
          params: newpayload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          console.log(response)
          var newObj = JSON.parse(JSON.stringify(response.data));
          newObj['dayNo'] = dayNo;
          commit('SET_RESOURCE_PERSONNEL_AVAILABILITY', newObj)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeRecord ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/user-management/users/${userId}`)
        .then((response) => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
