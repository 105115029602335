/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleChart from './chart/moduleChart.js'
import moduleCarePlan from './careplan/moduleCarePlan.js'
import moduleAppointment from './appointment/moduleAppointment.js'
import moduleResource from './resource/moduleResource.js'
import modulePatientFollower from './patientFollower/modulePatientFollower.js'
import moduleOrganization from "./organization/moduleOrganization";
import moduleSettings from "@/store/settings/moduleSettings";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    // chat: moduleChat,
    // email: moduleEmail,
    chart: moduleChart,
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    careplan: moduleCarePlan,
    appointment: moduleAppointment,
    resource: moduleResource,
    patientFollower: modulePatientFollower,
    organization: moduleOrganization,
    settings: moduleSettings,
  },
  strict: process.env.NODE_ENV !== 'production'
})
