/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'
import qs from 'qs'

export default {
  
  fetchAvgRevenueByPhysican ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      axios.get('/dashboard/avgRevenueByPhysican',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_AVG_REVENUE_BY_PHYSICAN', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGrossRevenue2years ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      axios.get('/dashboard/grossRevenue2years',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          
          commit('SET_GROSS_REVENUE_2_YEARS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPatientEncouterBreakdown ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      axios.get('/dashboard/patientEncouterBreakdown',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_PATIENT_ENCOUTER_BREAKDOWN', response.data.columnChart)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPatientEncouterBreakdownByDays ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      axios.get('/dashboard/patientEncouterBreakdownByDays',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_PATIENT_ENCOUTER_BREAKDOWN_BY_DAYS', response.data.columnChart)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchRevenueByClinic ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      axios.get('/dashboard/revenueByClinic',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_REVENUE_BY_CLINIC', response)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchRevenueOPvsCHC ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      console.log("START fetchRevenueOPvsCHC ");
      console.log(payload);
      console.log("END fetchRevenueOPvsCHC ");

      axios.get('/dashboard/revenueOPvsCHC',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_REVENUE_OP_VS_CHC', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFinancialOverview ({ commit }, payload) {
    return new Promise((resolve, reject) => {

      console.log("START fetchFinancialOverview ");
      console.log(payload);
      console.log("END fetchFinancialOverview ");
      axios.get('/dashboard/financialOverview',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_FINANCIAL_OVERVIEW', response.data.series)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPatientPayerBreakdownBySpeciliaty ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      
      axios.get('/dashboard/patientPayerBreakdownBySpeciliaty',
        {
          params: payload,
          paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
          }
        })
        .then((response) => {
          commit('SET_PATIENT_PAYER_BREAKDOWN_BY_SPECILIATY', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
