<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <div v-if="isFetched">
      <router-view @setAppClasses="setAppClasses" />
    </div>
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import jwt         from '@/http/requests/auth/jwt/index.js'
import {getToken} from "@/utils/storage";
import axios from "@/axios";
import {getOrganizationCredentials} from "@/api/organizations";

export default {
  data () {
    return {
      vueAppClasses: [],
      isFetched: false,
      organization: null,
    }
  },
  watch: {
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  methods: {
    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
    if (getToken()) {
      this.$store.dispatch('auth/fetchAuthUser').then(() => {
        this.$store.dispatch('auth/fetchPatient')
      })

    }
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  async created () {
    this.primaryColor = '#06b2a4';
    this.$vs.theme({ primary: '#06b2a4' })

    // jwt
    jwt.init()

    getOrganizationCredentials(location.hostname).then(res => {
      if (res.data.organization) {
        this.organization = res.data.organization.data[0]
        console.log(this.organization.custom_visual_banner_details)
        // this.organization.custom_visual_banner_details = JSON.parse(this.organization.custom_visual_banner_details)
        this.$store.dispatch('settings/setOrganizationDetails', this.organization)
        this.$store.dispatch('settings/setCustomStyle', this.organization)
        document.documentElement.style.setProperty('--lGreen', this.organization.custom_label_color)
        document.documentElement.style.setProperty('--labelColor', this.organization.custom_label_color)
        document.documentElement.style.setProperty('--inputField', this.organization.custom_input_field_color)
        document.documentElement.style.setProperty('--cardColor', this.organization.custom_listings_card_color_regular)
        document.documentElement.style.setProperty('--cardColorActive', this.organization.custom_listings_card_color_active)
        document.documentElement.style.setProperty('--cardTextColor', this.organization.custom_listings_card_text_color_)
      }
      if (res.data.addresses) {
        this.$store.dispatch('settings/setAddresses', res.data.addresses.docs[0].__onload.addr_list);
      }
      this.isFetched = true
    })

    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    // Auth0
    try       { await this.$auth.renewTokens() } catch (e) { console.error(e) }

  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>
