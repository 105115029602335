
const AUTH_TOKEN = 'HEALMINDER_AUTH_TOKEN'
const REFRESH_AUTH_TOKEN = 'HEALMINDER_AUTH_REFRESH_TOKEN'


export function getToken() {
  return localStorage.getItem(AUTH_TOKEN)
}

export function setToken(token) {
  localStorage.setItem(AUTH_TOKEN, token)
}

export function removeToken() {
  localStorage.removeItem(AUTH_TOKEN)
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_AUTH_TOKEN)
}

export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_AUTH_TOKEN, token)
}

export function removeRefreshToken() {
  localStorage.removeItem(REFRESH_AUTH_TOKEN)
}
