import Keycloak from "keycloak-js";
import {getToken, setToken} from "@/utils/storage";
import axios from "axios";

const keycloakInstance = new Keycloak();


const Login = (onAuthenticatedCallback) => {
  keycloakInstance
    .init({ onLoad: "login-required" })
    .then(function (authenticated) {
      console.log("login keycloak ", keycloakInstance.token)
      if (keycloakInstance.token) {
        setToken(keycloakInstance.token)
        axios.get(process.env.VUE_APP_API_URL+'me', {
          headers: {
            'Authorization': 'Bearer '+getToken(),
            'ContentType': 'application/json',
          }
        }).then(res => {
          console.log("profile info ", res.data)
        });

        // axios.get(process.env.VUE_APP_API_URL+"user/searchAll", {
        //   headers: {
        //     'Authorization': 'Bearer '+getToken(),
        //     'ContentType': 'application/json',
        //   }
        // }).then(res => {
        //   console.log("users ", res.data.users)
        // })

        // fetch(process.env.VUE_APP_API_URL+'me/', {
        //   method: 'get',
        //   headers: {
        //     'Authorization': 'Bearer '+keycloakInstance.token,
        //   }
        // })
      }
      authenticated ? onAuthenticatedCallback() : alert("non authenticated");
    })
    .catch((e) => {
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};

const KeyCloakService = {
  CallLogin: Login,
};

export default KeyCloakService;
