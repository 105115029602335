import state from './moduleOrganizationState.js'
import mutations from './moduleOrganizationMutations.js'
import actions from './moduleOrganizationActions.js'
import getters from './moduleOrganizationGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

