export default {
  company_name: '',
  custom_input_field_color: '',
  custom_label_color: '',
  custom_listings_card_color_regular: '',
  custom_listings_card_color_active: '',
  custom_listings_card_text_color_: '',
  custom_logo_link_s3: '',
  companyAddresses: [],
  organizationDetails: [],
}
