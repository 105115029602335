export default {
  setCustomStyle({commit}, data) {
    commit('SET_CUSTOM_STYLE', data)
  },
  setAddresses({commit}, data) {
    commit('SET_COMPANY_ADDRESSES', data)
  },
  setOrganizationDetails({commit}, data) {
    commit('SET_ORGANIZATION_DETAILS', data);
  }
}
