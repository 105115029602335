import axios from '@/axios.js'

export default {
  getOrganization({ commit }, orgId) {
    return new Promise((resolve, reject) => {
      axios.get(`organization/getById?id=${orgId}`)
        .then((response) => {
          commit('SET_ORGANIZATION', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
