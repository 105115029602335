import axios from '@/axios.js'
import qs from "qs";

export default {
  getAvailableFollowers({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/resourcePersonnel/filterResourcePersonnel?ageGroupsServed=any&department=any&gender=any&groupType=any&insuranceAccepted=any&language=any&level=any&organizationId=1&type=any&typeOfEmployment=any')
        .then((response) => {
          commit('SET_AVAILABLE_FOLLOWERS', response.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  assignFollower({ dispatch }, payload) {
    const params = payload.params;
    const data = payload.payload;
    return new Promise((resolve, reject) => {
      axios.post('/admission/updateById', data, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: 'repeat'})
        }
      })
        .then(response => {
          dispatch('getAvailableFollowers');
          dispatch('getAdmissions');
          resolve(response);
        })
        .catch((error) => {
          reject(error) ;
        });
    })
  },
  getAdmissions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/admission/getAll')
        .then((response) => {
          commit('SET_ADMISSIONS', response.data)
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
}
