// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html


import firebase from 'firebase/app'

// Initialize Firebase
var config = {
  /*apiKey: "AIzaSyCAuKH9WxgEWJIf4NIRhJWyYGL93tWDJ8Y",
  authDomain: "healthminder-eb6c7.firebaseapp.com",
  databaseURL: "https://healthminder-eb6c7.firebaseio.com",
  projectId: "healthminder-eb6c7",
  storageBucket: "healthminder-eb6c7.appspot.com",
  messagingSenderId: "382408458951",
  appId: "1:382408458951:web:a781cdf363c3541b9d74e2",
  measurementId: "G-22RZ5JQXEH"*/
  apiKey: "AIzaSyBBPCngXSjyHdTOhUS4tBQdu4juvcIZP9k",
  authDomain: "hminder-1e632.firebaseapp.com",
  databaseURL: "https://hminder-1e632.firebaseio.com",
  projectId: "hminder-1e632",
  storageBucket: "hminder-1e632.appspot.com",
  messagingSenderId: "382408458951",
  appId: "1:382408458951:web:a781cdf363c3541b9d74e2",
  measurementId: "G-22RZ5JQXEH"
}

firebase.initializeApp(config)


