/*=========================================================================================
  File Name: moduleEcommerceMutations.js
  Description: Ecommerce Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
//import * as moment from 'moment'


export default {
  SET_NEW_SERVICES (state, data) {
    // state.maternityNewServices = data.packgs;
    state.maternityNewServices = data;
  },
  SET_ALL_CARE_PLANS (state, data) {
    console.log(data);
    state.allCarePlans = {}
    state.allCarePlans = data.packgs


  },
  SET_ALL_CARE_PLANS_ORGID (state, data) {

    console.log("SET_ALL_CARE_PLANS_ORGID data");
    console.log(data);
    state.allCarePlansByOrgId = {}

    state.allCarePlansByOrgId = data.packgs


    var renderedCarePlansByOrgId = [];
    console.log(state.allCarePlansByOrgId.length);
    for (var k = 0; k < state.allCarePlansByOrgId.length; k++) {

        var carePlan = { carePlanId: '', label: '', description :'', acceptingNewPatient: '' , image:'', url:'', ageGroup:'', speciality: ''};
        //var urlSlot = { slotId: 0,quantity: 0, dayNo: selectedDayNo, startTime:"", endTime:"", dayMoment:"", url:""};
        //carePlan.packgId = state.allCarePlansByOrgId[k].packgId;
        carePlan.carePlanId =  state.allCarePlansByOrgId[k].carePlanId;
        //carePlan.label =  state.allCarePlansByOrgId[k].id;
        carePlan.id = state.allCarePlansByOrgId[k].id;
        carePlan.description = state.allCarePlansByOrgId[k].name;
        carePlan.acceptingNewPatient = state.allCarePlansByOrgId[k].acceptingNewPatient;
        carePlan.label = state.allCarePlansByOrgId[k].hospitalName;
        carePlan.ageGroup = state.allCarePlansByOrgId[k].ageGroup;
        carePlan.speciality = state.allCarePlansByOrgId[k].speciality;
        /*
        duration

        hospitalLatitude
        hospitalLongitude
        price
        speciality
        walkInPatient
        discount
        bookOnline

        */

        carePlan.image = state.allCarePlansByOrgId[k].image;

        carePlan.url = "/apps/preventiveHealth/healthpackage/" + state.allCarePlansByOrgId[k].organizationId +  "/"  + state.allCarePlansByOrgId[k].id;
        //
        renderedCarePlansByOrgId.push(carePlan);
      }

      console.log(renderedCarePlansByOrgId);
      state.allCarePlansByOrgIdList = renderedCarePlansByOrgId;




    //state.allCarePlansByOrgId = data.packgs






  },
  SET_ALL_REGISTRATION_CALL (state, data) {
    console.log(data);
    state.allRegistrationCalls = {}
    state.allRegistrationCalls = data


  },

  SET_ONE_SERVICE_REQUEST (state, data) {
    console.log(data);
    state.serviceRequest = {}
    state.serviceRequest = data
    state.serviceRequestActivities = data.activitys

  },
  SET_ONE_CAREPLAN (state, data) {
    console.log(data);
    state.careplanDetails = {}
    state.careplanDetails = data
    state.careplanServiceRequests = data.serviceRequests


  },

  SET_RESOURCE_PATTERN (state, data) {
    console.log(data);
    state.resourcePattern = {}
    state.resourcePattern = data

    state.heatMapChart = []



 var series = []
 for (var i = 0; i < state.resourcePattern.length; i++) {
   var pattern = []
//console.log(state.resourcePattern[i].resourceType);
   //if (state.resourcePattern[i].resourceType != "space"  || state.resourcePattern[i].resourceType != "consumable" ){
    for (var k = 0; k < state.resourcePattern[i].pattern.length; k++) {
      //var urlSlot = { slotId: 0,quantity: 0, dayNo: selectedDayNo, startTime:"", endTime:"", dayMoment:"", url:""};
        var urlSlot = { x: k + 1 ,y: state.resourcePattern[i].pattern[k]};
        pattern.push(urlSlot);
      }
      var serie = { name: "", data: []};
      serie.name = state.resourcePattern[i].resourceType;
      serie.data = pattern;
      series.push(serie);
    //}

    }

    console.log("SET_RESOURCE_PATTERN series");
    console.log(series);
    console.log("SET_RESOURCE_PATTERN series");
    state.heatMapChart= series;
 /*

 var series=  [{
    name: 'Equipment 1',
    data: pattern
  }];
 heatMapChartEquipment.series = [];

  */

  },
  SET_AVAILABLE_TIMESLOTS (state, data) {
    const selectedDayNo = data.dayNo;
    state.careplanAvailableSlots.morning = [];
    state.careplanAvailableSlots.afternoon = [];
    state.careplanAvailableSlots.afternoon = [];
    data.times12Start.forEach(function (slotData, index) {
      if (data.quantitys[0][index] === 0) {
        return;
      }
      const hours = slotData[0];
      const dayPart = slotData[3];
      let slot = {
        slotId: index + 1,
        quantity: data.quantitys[0][index],
        dayNo: selectedDayNo,
        slot: slotData
      };
      if (hours >= 12 && dayPart === 'am') {
        state.careplanAvailableSlots.morning.push(slot);
      }
      if (hours === 1 && dayPart === 'am') {
        state.careplanAvailableSlots.morning.push(slot);
      }
      if (hours < 12 && dayPart === 'am') {
        state.careplanAvailableSlots.morning.push(slot);
      }
      if (hours >= 12 && dayPart === 'pm') {
        state.careplanAvailableSlots.afternoon.push(slot);
      }
      if (hours >= 1 && hours < 6 && dayPart === 'pm') {
        state.careplanAvailableSlots.afternoon.push(slot);
      }
      if (hours >= 6 && hours < 12 && dayPart === 'pm') {
        state.careplanAvailableSlots.evening.push(slot);
      }
    });
  },
  TOGGLE_ITEM_IN_WISH_LIST (state, item) {
    const index = state.wishList.findIndex(i => i.objectID === item.objectID)
    if (index < 0) {
      state.wishList.push(item)
    } else {
      state.wishList.splice(index, 1)
    }
  },
  REMOVE_ITEM_FROM_CART (state, item) {
    const index = state.cartItems.findIndex(i => i.objectID === item.objectID)
    if (index > -1) { state.cartItems.splice(index, 1) }
  },
  ADD_ITEM_IN_CART (state, item) {
    state.cartItems.push(Object.assign({}, item))
  },
  UPDATE_ITEM_QUANTITY (state, payload) {
    state.cartItems[payload.index].quantity = payload.quantity
  }
}

