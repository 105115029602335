export default {
  patientType: 'Type de patient',
  existing: 'Existant',
  new: 'Nouveau',
  gender: 'Genre',
  female: 'Femelle',
  male: 'Male',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  birthDate: 'Date de naissance',
  mobileNumber: 'Mobile',
  email: 'E-mail',
  address: 'Adresse',
  postalCode: 'Code Postal',
  city: 'Ville',
  submit: 'Soumettre',
  nationality: 'Nationalité',
  job: 'Emploi',
  username: 'Nom d\'utilisateur',
  password: 'Mot de passe',
  login: 'Se connecter',
  fullName: 'Nom et prénom',
  doctorName: 'Nom du médecin',
  appointmentDate: 'Date de rendez-vous',
  notes: 'Remarques',
  confirm: 'Confirmer',
  goToHomePage: 'Aller à la page d\'accueil',
  navbar: {
    healthPlan: 'Plan de santé et dépistage',
    documentCenter: 'Centre de documentation',
    checklist: 'Liste de contrôle'
  },
  landingPage: {
    bookNow: 'Reserver maintenant',
    whatsNew: 'QUOI DE NEUF',
    recommendedClasses: 'RECOMMANDATIONS',
    mayJoinClasses: 'AUTRES RECOMMANDATIONS'
  },
  healthPackage: {
    selectDate: 'Sélectionnez la date et l\'heure',
    fillInformation: 'Remplissez vos informations',
    confirmation: 'Confirmation',
    confirmDate: 'Confirmer la Date',
    confirmTime: 'Confirmer l\'heure',
    morning: 'Matin',
    afternoon: 'Après-midi',
    evenening: 'Soirée',
    slots: 'Créneau horaire',
  },
  book: {
    packageDescription: 'Description du plan de santé',
    selectDate: 'Sélectionnez la date et l\'heure',
    fillInformation: 'Remplissez vos informations',
    confirmation: 'Confirmation',
    successfullBooking: 'Rendez-vous pris avec succès, Merci pour votre réservation !',
    beforeBooking: 'Merci de vérifier les détails de votre rendez-vous avant de le confirmer !',
    checkDetailsBeforeBooking: 'Veuillez vérifier les détails ci-dessous pour votre prochain rendez-vous :',
    appointmentNotes: 'Les détails de ce rendez-vous seront envoyés à votre adresse, veuillez vérifier votre courrier électronique pour votre rappel.',
  }
}

