export default {
  patientType: 'Tipo de paciente',
  existing: 'Existente',
  new: 'Nuevo',
  gender: 'Género',
  female: 'Femenino',
  male: 'Masculino',
  firstName: 'Nombre de pila',
  lastName: 'Apellido',
  birthDate: 'Fecha de nacimiento',
  mobileNumber: 'Número de teléfono móvil',
  email: 'Correo electrónico',
  address: 'Dirección',
  postalCode: 'Código Postal',
  city: 'Ciudad',
  submit: 'Entregar',
  nationality: 'Nacionalidad',
  job: 'Trabajo',
  username: 'Nombre de usuario',
  password: 'Contraseña',
  login: 'Acceso',
  fullName: 'Nombre completo',
  doctorName: 'Nombre del médico',
  appointmentDate: 'Día de la cita',
  notes: 'Notas',
  confirm: 'Confirmar',
  goToHomePage: 'Ir a la página de inicio',
  landingPage: {
    bookNow: 'Reservar ahora',
    whatsNew: 'QUÉ HAY DE NUEVO',
    recommendedClasses: 'CLASES RECOMENDADAS',
    mayJoinClasses: 'TAMBIÉN QUIERES UNIRTE A ESTAS CLASES'
  },
  navbar: {
    healthPlan: 'Plan de salud y detección',
    documentCenter: 'Centro de documentos',
    checklist: 'Lista de Verificación'
  },
  healthPackage: {
    selectDate: 'Seleccione fecha y hora',
    fillInformation: 'Llena tu información',
    confirmation: 'Confirmación',
    confirmDate: 'Confirmar fecha',
    confirmTime: 'Confirmar hora',
    morning: 'Mañana',
    afternoon: 'Tarde',
    evening: 'Noche',
    slots: 'Franja horaria',
  },
  book: {
    packageDescription: 'Descripción del plan de salud',
    selectDate: 'Seleccione fecha y hora',
    fillInformation: 'Llena tu información',
    confirmation: 'Confirmación',
    successfullBooking: 'Cita reservada con éxito. ¡Gracias por tu reserva!',
    beforeBooking: '¡Por favor revisa los detalles de tu cita antes de conformarla!',
    checkDetailsBeforeBooking: 'Por favor verifique los detalles a continuación para su próxima cita:',
    appointmentNotes: 'Los detalles de esta cita se enviarán a su dirección; consulte su correo electrónico para recibir un recordatorio.',
  }
}
