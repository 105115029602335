import state from './modulePatientFollowerState.js'
import mutations from './modulePatientFollowerMutations.js'
import actions from './modulePatientFollowerActions.js'
import getters from './modulePatientFollowerGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
