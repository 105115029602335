import jwt from '../../http/requests/auth/jwt/index.js';
import axios from '@/axios.js';
import firebase from 'firebase/app';
import 'firebase/auth';
import router from '@/router';
import { Role } from '@/model/role';
import {getRefreshToken, getToken, removeRefreshToken, removeToken, setRefreshToken, setToken} from "@/utils/storage";

export default {
  loginAttempt({dispatch}, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {

      // Change firebase Persistence
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

        // If success try to login
        .then(function () {
          dispatch('login', newPayload)
        })

        // If error notify
        .catch(function (err) {

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    } else {
      // Try to login
      return dispatch('login', newPayload)
    }
  },
  /* eslint-disable no-unused-vars */
  loginEmailLink({commit}, payload) {
    const actionCodeSettings = {
      url: `${location.origin}/pages/loginEmailLink`,
      handleCodeInApp: true
    };
    payload.notify({
      title: 'Login Attempt',
      text: 'Confirmation login link was sent to your email',
      iconPack: 'feather',
      icon: 'icon-check',
      color: 'success'
    })
    firebase.auth().sendSignInLinkToEmail(payload.email, actionCodeSettings)
      .then((result) => {
        window.localStorage.setItem('signInEmail', payload.email);
      }, (err) => {
        console.log(err);
      });
  },

  checkLoginUrl({commit}, payload) {
    const email = window.localStorage.getItem('signInEmail');
    if (email) {
      if (firebase.auth().isSignInWithEmailLink(payload)) {
        firebase.auth().signInWithEmailLink(email, payload)
          .then((result) => {
            axios.get("/user/searchByEmail", {
                params: {
                  userName: email
                }
              })
              .then(response => {
                if (response.data.firstName) {
                  window.localStorage.removeItem('signInEmail');
                  commit('UPDATE_USER_INFO', response.data, {root: true});
                  const roleId = Object.values(Role).find(value => value === response.data.roleId);
                  if (roleId === Role.visitor) {
                    router.push('/apps/preventiveHealth');
                  }
                  if (roleId === Role.user) {
                    router.push('/apps/preventiveHealth');
                  }
                  if (roleId === Role.admin) {
                    router.push('/dashboard/checklist');
                  }
                } else {
                  router.push('/pages/loginEmailLink');
                }
              }).catch((err) => {
                router.push('/pages/loginEmailLink');
              });
          })
          .catch((err) => {
            router.push('/pages/loginEmailLink');
          });
      }
    }
  },
  loginUser({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_GATEWAY_URL+'/auth/login', data).then(res => {
        setToken(res.data.access_token)
        setRefreshToken(res.data.refresh_token)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })

  },
  getUserProfile({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_GATEWAY_URL+'/me', {headers: {
        'Authorization': 'Bearer '+getToken(),
        }}).then(res => {
        commit('SET_USER_PROFILE', res.data)
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  fetchNewAccessToken({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/refresh',
        method: 'post',
        data: {
          refresh_token: getRefreshToken()
        }
      }).then(res => {
        setToken(res.data.access_token)
        setRefreshToken(res.data.refresh_token)
        resolve(res)
      }).catch(err => {
        console.log("errrr ", err.response)
        if (err.response.data.message === 'Unauthorized') {
          removeToken()
          removeRefreshToken()
          window.location.href = '/pages/loginEmailLink'
        }
        reject(err)
      })
    })
  },
  fetchPatient({commit, state}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `/api/resource/Patient?fields=["*"]&filters=[["custom_external_id","=", "${state.userProfile['id']}"]]`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
        },
      }).then(res => {
        commit('SET_PATIENT_DATA', res.data.data[0])
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  fetchAuthUser({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/me',
        method: 'get',
        headers: {
          'Authorization': 'Bearer '+getToken(),
        }
      }).then(res => {
        commit('SET_USER_PROFILE', res.data)
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* eslint-enable no-unused-vars */
  login({commit, state, dispatch}, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      console.log('already logged');
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }
    // Try to sigin
    return firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then((result) => {
        // Set FLAG username update required for updating username
        //let isUsernameUpdateRequired = false

        // if username is provided and updateUsername FLAG is true
        // set local username update FLAG to true
        // try to update username
        if (payload.updateUsername && payload.userDetails.displayName) {
          //isUsernameUpdateRequired = true
          dispatch('updateUsername', {
            user: result.user,
            username: payload.userDetails.displayName,
            notify: payload.notify,
            isReloadRequired: true
          })
        }

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation();

        return new Promise((resolve, reject) => {
          axios.get('/user/searchByEmail', {
              params: {
                userName: payload.userDetails.email
              }
            })
            .then((response) => {
              commit('UPDATE_USER_INFO', response.data, {root: true});
              resolve(response)
            })
            .catch((error) => { reject(error) })
        })
      }, (err) => {
        console.log(err)
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Google Login
  loginWithGoogle({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
      payload.notify({
        time: 2500,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },

  // Facebook Login
  loginWithFacebook({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
      payload.notify({
        time: 2500,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },

  // Twitter Login
  loginWithTwitter({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.TwitterAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
      payload.notify({
        time: 2500,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },

  // Github Login
  loginWithGithub({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GithubAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
      payload.notify({
        time: 2500,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },
  registerUser({dispatch}, payload) {
    // create user using firebase
    firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then((result) => {
        payload.notify({
          title: 'Account Created',
          text: 'You are successfully registered!',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        });

        const registrationPayload = {
          "acceptReceiveInfo": true,
          "dob": "string",
          "emailAddress": payload.userDetails.email,
          "firstName": payload.userDetails.displayName,
          "firebaseUID": result.user.uid,
          "gender": "string",
          "icNumber": "string",
          "language": "string",
          "lastName": payload.userDetails.displayName,
          "mobileNumber": "string",
          "password": payload.userDetails.password,
          "orgId": 1,
          "roleId": 1
        }

        axios.post("/user/register", registrationPayload)
          .then(() => {
            const loginPayload = {
              userDetails: payload.userDetails,
              notify: payload.notify,
              updateUsername: true
            }
            dispatch('login', loginPayload)
          }).catch((err) => {
            console.log(err);
        });
      }, (error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  updateUsername({commit}, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },


  // JWT
  loginJWT({commit}, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {

          // If there's user data in response
          if (response.data.userData) {
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || '/')

            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken)

            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
          reject(error)
        })
    })
  },
  registerUserJWT({commit}, payload) {

    const {displayName, email, password, confirmPassword} = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => {
        resolve(response)
      })
    })
  },
  logout() {
    // localStorage.removeItem('userInfo');
    //
    // // if user is logged in via firebase
    // const firebaseCurrentUser = firebase.auth().currentUser
    //
    // if (firebaseCurrentUser) {
    //   firebase.auth().signOut().then(() => {
    //     router.push('/pages/loginEmailLink').catch(() => {})
    //   })
    // }
    // // If JWT login
    // if (localStorage.getItem('accessToken')) {
    //   localStorage.removeItem('accessToken')
    //   router.push('/pages/loginEmailLink').catch(() => {})
    // }

    axios({
      url: '/auth/logout',
      method: 'post',
      data: {
        refresh_token: getRefreshToken(),
      }
    }).then(() => {
      removeToken()
      removeRefreshToken()
      // This is just for demo Purpose. If user clicks on logout -> redirect
      router.push('/pages/loginEmailLink').catch(() => {})
    })
  }
}
