/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_APPOINTMENTS (state, data) {
    state.appointments = data.appointments
    var renderedPatientList = [];
    for (var k = 0; k < state.appointments.length; k++) {
      var p = { appointmentId: "", patientId: "", firstName: "", lastName: "", appointmentDate:"", appointmentTime: ""};
      p.appointmentId = state.appointments[k].appointmentId;
      p.patientId = state.appointments[k].patient.uuid;
      p.firstName = state.appointments[k].firstName;
      p.lastName = state.appointments[k].lastName;
      p.appointmentDate = state.appointments[k].startDay + "-" + state.appointments[k].startMonth + "-" + state.appointments[k].startYear;
      p.appointmentTime = state.appointments[k].startHour + ":" + state.appointments[k].startMinute;
      renderedPatientList.push(p);
    }
    state.appointments = renderedPatientList;
  },
  SET_USERS (state, users) {
    state.users = users
  },
  SET_APPOINTMENTS_CUSTOMER (state, users) {
    state.users = users
  },
  REMOVE_RECORD (state, itemId) {
    const userIndex = state.users.findIndex((u) => u.id === itemId)
    state.users.splice(userIndex, 1)
  },
  BOOK_APPOINTMENT_SUCCESS(state, data) {
    state.loading = false;
    console.log(state, data);
  },
  SET_LOADING(state, data) {
    state.loading = data;
  },
  SET_SUGGESTED_CLASSES(state, data) {
    state.suggestedClasses.push(data);
  },
}
