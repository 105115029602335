export default {
  patientType: 'Тип пациента',
  existing: 'Существующий',
  new: 'Новый',
  gender: 'Пол',
  female: 'Женский',
  male: 'Мужской',
  firstName: 'Имя',
  lastName: 'Фамилия',
  birthDate: 'Дата рождения',
  mobileNumber: 'Номер мобильного телефона',
  email: 'Электронная почта',
  address: 'Адрес',
  postalCode: 'Почтовый индекс',
  city: 'Город',
  submit: 'Представлять на рассмотрение',
  nationality: 'Национальность',
  job: 'Работа',
  username: 'Имя пользователя',
  password: 'Пароль',
  login: 'Авторизоваться',
  fullName: 'Полное имя',
  doctorName: 'Имя врача',
  appointmentDate: 'Назначенная дата',
  notes: 'Примечания',
  confirm: 'Подтверждать',
  goToHomePage: 'Вернуться на домашнюю страницу',
  navbar: {
    healthPlan: 'План медицинского обслуживания и скрининг',
    documentCenter: 'Центр документов',
    checklist: 'Контрольный список'
  },
  landingPage: {
    bookNow: 'Забронируйте сейчас',
    whatsNew: 'ЧТО НОВОГО',
    recommendedClasses: 'РЕКОМЕНДУЕМЫЕ КЛАССЫ',
    mayJoinClasses: 'ВЫ ТАКЖЕ МОЖЕТЕ ПРИСОЕДИНИТЬСЯ К ЭТИМ КЛАССАМ'
  },
  healthPackage: {
    selectDate: 'Выберите дату и время',
    fillInformation: 'Заполните свою информацию',
    confirmation: 'Подтверждение',
    confirmDate: 'Подтвердить дату',
    confirmTime: 'Подтвердить время',
    morning: 'Утро',
    afternoon: 'Полдень',
    evening: 'Вечер',
    slots: 'Временной интервал',
  },
  book: {
    packageDescription: 'Описание плана медицинского страхования',
    selectDate: 'Выберите дату и время',
    fillInformation: 'Заполните свою информацию',
    confirmation: 'Подтверждение',
    successfullBooking: 'Встреча успешно забронирована. Спасибо за бронирование!',
    beforeBooking: 'Пожалуйста, проверьте детали вашей встречи, прежде чем подтвердить ее!',
    checkDetailsBeforeBooking: 'Пожалуйста, проверьте информацию ниже о предстоящей встрече:',
    appointmentNotes: 'Подробности встречи будут отправлены на ваш адрес. Проверьте свою электронную почту, чтобы получить напоминание.',
  }
}
