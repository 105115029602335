export default{
  SET_CUSTOM_STYLE(state, payload) {
    state.company_name = payload.company_name
    state.custom_input_field_color = payload.custom_input_field_color
    state.custom_label_color = payload.custom_label_color
    state.custom_listings_card_color_regular = payload.custom_listings_card_color_regular
    state.custom_listings_card_color_active = payload.custom_listings_card_color_active
    state.custom_listings_card_text_color_ = payload.custom_listings_card_text_color_
    state.custom_logo_link_s3 = payload.custom_logo_link_s3
  },

  SET_COMPANY_ADDRESSES(state, payload) {
    state.companyAddresses = payload;
  },
  SET_ORGANIZATION_DETAILS(state, payload) {
    state.organizationDetails = payload;
  }
}
