export default {
  patientType: 'Tipus de pacient',
  existing: 'Existent',
  new: 'Nou',
  gender: 'Gènere',
  female: 'Dona',
  male: 'Mascle',
  firstName: 'Nom',
  lastName: 'Cognom',
  birthDate: 'Data de naixement',
  mobileNumber: 'Número de mòbil',
  email: 'Correu electrònic',
  address: 'Adreça',
  postalCode: 'Codi Postal',
  city: 'Ciutat',
  submit: 'Presentar',
  nationality: 'Nacionalitat',
  job: 'Treball',
  username: 'Nom d\'usuari',
  password: 'Contrasenya',
  login: 'Iniciar Sessió',
  fullName: 'Nom complet',
  doctorName: 'Nom del doctor',
  appointmentDate: 'Data de cita',
  notes: 'Notes',
  confirm: 'Confirmeu',
  goToHomePage: 'Vés a la pàgina d\'inici',
  navbar: {
    healthPlan: 'Pla de salut i detecció',
    documentCenter: 'Centre de Documentació',
    checklist: 'Llista de verificació'
  },
  landingPage: {
    whatsNew: 'QUE HI HA DE NOU',
    recommendedClasses: 'CLASSES RECOMANADES',
    mayJoinClasses: 'TAMBÉ POTS VOLS UNIR-TE A AQUESTES CLASSES',
    bookNow: 'Reservar ara',
  },
  healthPackage: {
    packageDescription: 'Descripció del Pla de Salut',
    selectDate: 'Seleccioneu la data i l\'hora',
    fillInformation: 'Omple la teva informació',
    confirmation: 'Confirmació',
    confirmDate: 'Confirmeu la data',
    confirmTime: 'Confirmeu l\'hora',
    morning: 'Al matí',
    afternoon: 'Tarda',
    evening: 'Vespre',
    slots: 'Interval de temps',
  },
  book: {
    selectDate: 'Seleccioneu la data i l\'hora',
    fillInformation: 'Omple la teva informació',
    confirmation: 'Confirmació',
    successfullBooking: 'Cita reservada amb èxit, gràcies per la vostra reserva!',
    beforeBooking: 'Si us plau, comproveu els detalls de la vostra cita abans de conformar-la!',
    checkDetailsBeforeBooking: 'Si us plau, comproveu els detalls a continuació per a la vostra propera cita:',
    appointmentNotes: 'Els detalls d\'aquesta cita s\'enviaran a la vostra adreça, si us plau, comproveu el vostre correu electrònic per rebre el recordatori.',
  }
}
