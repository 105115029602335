import mock from '@/fake-db/mock.js'

// Contact
const data = {
  tasks: [
    {
      'id': 10,
      'title': 'Screening',
      'desc': 'A customizable report of Health Check Ups.',
      'isImportant': true,
      'isStarred': false,
      'tags': ['screening'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 11,
      'title': 'Patient by segment Report',
      'desc': 'A list of patient with a specific segment.',
      'isImportant': false,
      'isStarred': true,
      'tags': ['patient'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 15,
      'title': 'Patient No-show',
      'desc': 'A list of no-show appointments by Appointment Date.',
      'isImportant': true,
      'isStarred': true,
      'tags': ['patient'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 18,
      'title': 'Patient Birthdays',
      'desc': 'Patient listed by Birth Date',
      'isImportant': false,
      'isStarred': false,
      'tags': ['patient'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 23,
      'title': 'Patient Without Appointments',
      'desc': 'A list of patient without any future Appointment',
      'isImportant': false,
      'isStarred': false,
      'tags': ['frontend'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 24,
      'title': 'Patient Without One  Past Appointment',
      'desc': 'A list of patient with Past Appointment',
      'isImportant': false,
      'isStarred': false,
      'tags': ['patient'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 26,
      'title': 'Patient Born at the Hospital Without Future Appointment',
      'desc': 'A list of patient with Future Appointment',
      'isImportant': false,
      'isStarred': false,
      'tags': ['patient'],
      'isCompleted': false,
      'isTrashed': false
    },
    {
      'id': 25,
      'title': 'Promotion',
      'desc': 'Promotion',
      'isImportant': false,
      'isStarred': false,
      'tags': ['patient'],
      'isCompleted': false,
      'isTrashed': false
    }
  ],
  taskTags: [
    {
      id: 5,
      text: 'Patient',
      value : 'patient',
      color: 'primary'
    },
    {
      id: 7,
      text: 'Practionner',
      value: 'backend',
      color: 'secondary'
    },
    {
      id: 8,
      text: 'Screening',
      value: 'screening',
      color: 'success'
    }/*,
    {
      id: 11,
      text: 'Bug',
      value: 'bug',
      color: 'danger'
    }*/
  ]
}

// POST : Add new Tasks
mock.onPost('/api/apps/todo/tasks/').reply((request) => {

  // Get task from post data
  const task = JSON.parse(request.data).task

  const length = data.tasks.length
  let lastIndex = 0
  if (length) {
    lastIndex = data.tasks[length - 1].id
  }
  task.id = lastIndex + 1

  data.tasks.push(task)

  return [201, {id: task.id}]
})

// GET: Fetch Todos
mock.onGet('api/apps/todo/tasks').reply((request) => {

  const filter = request.params.filter

  const filteredTasks = data.tasks.filter((task) => {

    // If filter == all
    if (filter === 'all') return !task.isTrashed

    // If filter == starred
    if (filter === 'starred') return !task.isTrashed && task.isStarred

    // If filter == important
    if (filter === 'important') return !task.isTrashed && task.isImportant

    // If filter == completed
    if (filter === 'completed') return !task.isTrashed && task.isCompleted

    // If filter == trashed
    if (filter === 'trashed') return task.isTrashed

    else return task.tags.includes(filter)

  })


  return [200, JSON.parse(JSON.stringify(filteredTasks)).reverse()]
})

// GET: Fetch tags
mock.onGet('api/apps/todo/tags').reply(() => {
  return [200, JSON.parse(JSON.stringify(data.taskTags))]
})

// POST: Update Task
mock.onPost(/\/api\/apps\/todo\/task\/\d+/).reply((request) => {

  const taskId = request.url.substring(request.url.lastIndexOf('/') + 1)

  const task = data.tasks.find((task) => task.id == taskId)
  // task.title = JSON.parse(request.data).task.title
  Object.assign(task, JSON.parse(request.data).task)

  return [200, task]
})
