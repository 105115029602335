/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  fetchAppointments({ commit }) {
    return new Promise((resolve, reject) => {
      console.log("fetchAppointments : ");
      axios.get('/appointment/getAll')
        .then((response) => {
          console.log(response)
          commit('SET_APPOINTMENTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAppointmentsByCustomer({ commit }) {
    return new Promise((resolve, reject) => {
      console.log("fetchAppointments : ");
      axios.get('/appointment/getAll')
        .then((response) => {
          console.log(response)
          commit('SET_APPOINTMENTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAppointment (context, appointmentId) {
    return new Promise((resolve, reject) => {
      axios.get('/appointment/getByUUID?id=' + appointmentId)
        .then((response) => {
          console.log(response);
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeRecord ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/user-management/users/${userId}`)
        .then((response) => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  bookAppointment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      axios.post('/appointment/save', payload)
        .then(response => {
          commit('BOOK_APPOINTMENT_SUCCESS', response);
          resolve(response);
        })
        .catch((error) => { reject(error) });
    })
  },
  suggestedClasses({ commit }, userId) {
    //TODO get real userId
    userId = 3;
    return new Promise((resolve, reject) => {
      axios.get(`/appointment/suggestedPackgs?patientId=${userId}`)
        .then((response) => {
          response.data.packgIds.forEach(packageId => {
            axios.get('/packg/getById?id=' + packageId)
              .then((response) => {
                commit('SET_SUGGESTED_CLASSES', response.data)
                resolve(response)
              })
              .catch((error) => { reject(error) })
          })
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
